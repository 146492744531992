import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
// import ReactPlayer from "react-player"
import IconPlay from "../img/icons/play.svg"
import Img from "gatsby-image"
import YouTube from "react-youtube"

function YouTubeGetID(url) {
	url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0]
}

const VideoSection = (props) => {
	const [ShowPlayer, setShowPlayer] = useState(false) 
	const { heroimage, herovideo } = props
	const videoId = YouTubeGetID(herovideo)
	const videoOpts = {
		playerVars: {
			autoplay: 1,
		},
	}

	const heroBackground =
		heroimage &&
		heroimage.childImageSharp &&
		heroimage.childImageSharp.fluid ? (
			<Img
				className="video-container-background"
				fluid={heroimage.childImageSharp.fluid}
			/>
		) : (
			<div
				className="video-container-background"
				style={{ backgroundColor: "#c00" }}
			/>
		)

	const _onReady = (event) => {
		console.log(`youtube player event`, event)
	}

	return (
		<section>
			<div className="container">
				{!ShowPlayer && (
					<div className="video-container-16-9">
						<button className="video-container-inner" onClick={() => setShowPlayer(true)}>
							{heroBackground}
							<IconPlay className="video-container-playbutton" />
						</button>
					</div>
				)}
				{ShowPlayer && (
					<YouTube
						videoId={videoId}
						opts={videoOpts}
						onReady={_onReady}
						containerClassName="video-container-16-9"
						className="video-container-inner"
					/>
				)}
			</div>
		</section>
	)
}


VideoSection.propTypes = {
	data: PropTypes.shape({}),
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query VideoSectionQuery {
				stringsYaml {
					googlemapsAPIkey
				}
				contactinfoYaml {
					location
				}
			}
		`}
		render={({ stringsYaml, contactinfoYaml }) => (
			<VideoSection data={{ ...stringsYaml, ...contactinfoYaml }} {...props} />
		)}
	/>
)
